import { ArrowLeftOutlined, FolderTwoTone } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import PSPDFKitWeb from 'pspdfkit';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import PdfReader from '@common/components/PdfReader';
import { useDocumentInstance } from '@common/components/PdfReader/hooks';
import CheckIcon from '@common/icons/CheckIcon';
import PspdfkitConfig from '@config/PspdfkitConfig';
import { FILLABLE_FORM_STATUS } from '@modules/fillable-form/constants';
import { getUnstyledAnnotations } from '@trustlayer/common';

const filterToolbarItems = (items) => {
  return items.filter(
    ({ dropdownGroup, type }) =>
      dropdownGroup !== 'actionsGroup' &&
      type !== 'search' &&
      type !== 'document-editor' &&
      type !== 'export-pdf' &&
      !type.startsWith('sidebar-') &&
      dropdownGroup !== 'drawingGroup' &&
      ![
        'annotate',
        'signature',
        'arrow',
        'rectangle',
        'ellipse',
        'polygon',
        'polyline',
        'note',
        'text',
        'line',
      ].includes(type),
  );
};

const FillDocumentForm = ({
  documentForm,
  project,
  filler,
  onBackButtonClick,
  onSubmit,
}) => {
  const { documentInstance } = useDocumentInstance();
  const [formFieldValues, setFormFieldValues] = useState({});
  const [formStatus, setFormStatus] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const form = R.propOr({}, 'fillableForm', documentForm);
  const annotations = R.propOr([], ['annotations'], form);
  const filledFieldsCount = Object.keys(
    formStatus.requiredFilledFields || {},
  ).length;
  const areAllFieldsFilled =
    filledFieldsCount === formStatus.requiredFieldNames?.length;

  const updateFormStatus = useCallback(async () => {
    const formFields = await documentInstance.getFormFields();

    const requiredFieldNames = R.compose(
      R.pluck('formFieldName'),
      R.filter(({ formFieldName }) => {
        const formField = R.find(R.propEq('name', formFieldName), formFields);
        return formField?.required;
      }),
      R.filter(R.pathEq(['customData', 'association'], filler)),
      R.propOr([], 'annotations'),
    )(form);

    const requiredFieldValues = R.reduce(
      (acc, formFieldName) => ({
        ...acc,
        [formFieldName]: R.prop(formFieldName, formFieldValues),
      }),
      {},
      requiredFieldNames,
    );

    const requiredFilledFields = R.filter((value) => {
      // checkbox
      if (Array.isArray(value) && value[0] === 'Off') {
        return false;
      }

      if ([null, undefined, ''].includes(value)) {
        return false;
      }

      return true;
    }, requiredFieldValues);

    setFormStatus({
      requiredFieldNames,
      requiredFilledFields,
    });
  }, [documentInstance, formFieldValues, filler, form]);

  const handleOnFormFieldValuesUpdate = useCallback(async (formFields) => {
    const newFormFields = formFields.toArray().reduce(
      (acc, field) => ({
        ...acc,
        [field.name]: field.value,
      }),
      {},
    );

    setFormFieldValues((formFieldValues) => ({
      ...formFieldValues,
      ...newFormFields,
    }));
  }, []);

  const onInkSignatureCreated = useCallback(
    async (inkSignature) => {
      const annotationsInSamePage = await documentInstance.getAnnotations(
        inkSignature.pageIndex,
      );

      const signedAnnotationField = annotationsInSamePage?.find(
        (annotation) =>
          !annotation.isSignature &&
          annotation?.boundingBox?.isRectOverlapping(inkSignature.boundingBox),
      );

      // ink signature value is set to null from PSPDFKit sdk. In our state we set it to true
      setFormFieldValues((formFieldValues) => ({
        ...formFieldValues,
        [signedAnnotationField?.formFieldName]: true,
      }));
    },
    [documentInstance],
  );

  const handleOnAnnotationsCreate = useCallback(
    async (annotations) => {
      const lastAnnotation = annotations.get(0);

      if (lastAnnotation.isSignature) {
        onInkSignatureCreated(lastAnnotation);
      }
    },
    [onInkSignatureCreated],
  );

  const handleOnSubmit = async () => {
    const isLastSigner = form.status === FILLABLE_FORM_STATUS.ONE_TO_GO;
    const instantJSON = await documentInstance.exportInstantJSON();
    const instantJSONWithUnstyledAnnotations = R.assoc(
      'annotations',
      getUnstyledAnnotations(instantJSON.annotations),
      instantJSON,
    );

    setIsSubmitLoading(true);

    if (!isLastSigner) {
      await onSubmit({
        _id: documentForm?._id,
        fillableForm: instantJSON,
        saveType: 'ALL',
      });
    } else {
      // @todo: add a loader in the Save button
      const headlessInstance = await PSPDFKitWeb.load({
        ...PspdfkitConfig,
        headless: true,
        document: documentForm?.url,
        instantJSON: instantJSONWithUnstyledAnnotations,
      });

      const documentFile = await headlessInstance.exportPDF({ flatten: true });
      await onSubmit({
        _id: documentForm?._id,
        fillableForm: instantJSON,
        documentFile: new Blob([documentFile]),
        saveType: 'ALL',
      });
    }

    setIsSubmitLoading(false);
  };

  const handleOnBackButtonClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to close?',
      content: 'All unsaved changes will be lost.',
      okText: 'Close anyway',
      cancelText: 'Cancel',
      onOk: onBackButtonClick,
      width: 480,
      zIndex: 1005,
    });
  };

  const getDisabledAnnotations = ({ annotations = [], keepStyledTypes = [] }) =>
    annotations.map((ann) =>
      ann.type !== 'pspdfkit/widget' ||
      keepStyledTypes.includes(ann.customData?.association)
        ? ann
        : {
            ...ann,
            backgroundColor: '#efefef',
            borderWidth: 1,
          },
    );

  useEffect(() => {
    if (!documentInstance) {
      return;
    }

    documentInstance.setIsEditableAnnotation(
      (annotation) => annotation?.customData?.association === filler,
    );
  }, [documentInstance, filler]);

  useEffect(() => {
    if (!documentInstance) {
      return;
    }

    documentInstance.addEventListener(
      'formFieldValues.update',
      handleOnFormFieldValuesUpdate,
    );
    documentInstance.addEventListener(
      'annotations.create',
      handleOnAnnotationsCreate,
    );

    return () => {
      documentInstance.removeEventListener(
        'formFieldValues.update',
        handleOnFormFieldValuesUpdate,
      );
      documentInstance.removeEventListener(
        'annotations.create',
        handleOnAnnotationsCreate,
      );
    };
  }, [
    documentInstance,
    handleOnFormFieldValuesUpdate,
    handleOnAnnotationsCreate,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    if (documentInstance) {
      updateFormStatus();
    }
  }, [formFieldValues, updateFormStatus, documentInstance]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    /**
     * @note update, on the first render, formFieldValues with pre-filled values from the server
     * (i.e. linked custom fields)
     */
    if (documentForm?._id) {
      const values = form.formFieldValues.reduce(
        (acc, { name, value }) => ({
          ...acc,
          [name]: value,
        }),
        {},
      );

      setFormFieldValues(values);
    }
  }, [documentForm?._id]);

  return (
    <FillDocumentForm.Wrapper>
      <main>
        <header>
          <Row justify="space-between" align="middle">
            <Col>
              <Row>
                <Col>
                  <div className="backButton">
                    <ArrowLeftOutlined onClick={handleOnBackButtonClick} />
                  </div>
                </Col>
                <Col>
                  {project?.name && (
                    <div className="projectName">
                      <FolderTwoTone />
                      {project.name}
                    </div>
                  )}
                  <div className="documentFormName">
                    {documentForm?.friendlyName}
                  </div>
                </Col>
              </Row>
            </Col>

            {Boolean(documentInstance) && (
              <Col>
                <span className="actionButtonHelpLabel">
                  {filledFieldsCount ===
                    formStatus.requiredFieldNames?.length && <CheckIcon />}
                  <span className="actionButtonHelpLabelBadge">
                    {filledFieldsCount}/{formStatus.requiredFieldNames?.length}
                  </span>
                  required fields
                </span>
                <Button
                  type="primary"
                  data-cy="submitDocumentForm"
                  disabled={!areAllFieldsFilled}
                  onClick={handleOnSubmit}
                  loading={isSubmitLoading}
                >
                  Save
                </Button>
              </Col>
            )}
          </Row>
        </header>
        <PdfReader
          document={R.assocPath(
            ['fillableForm', 'annotations'],
            getDisabledAnnotations({ annotations, keepStyledTypes: [filler] }),
            documentForm,
          )}
          filterToolbarItems={filterToolbarItems}
        />
      </main>
    </FillDocumentForm.Wrapper>
  );
};

FillDocumentForm.Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;

  .backButton {
    .anticon {
      font-size: 18px;
      cursor: pointer;
      margin-right: 24px;

      &:hover {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }

  > main {
    flex: auto;
    display: flex;
    flex-direction: column;

    > header {
      padding: 16px 24px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray};
    }
  }

  .actionButtonHelpLabel {
    font-size: 12px;
    margin-right: 24px;
    margin-left: 42px;
  }

  .actionButtonHelpLabelBadge {
    border-radius: 30px;
    border: 1px solid #e0e6ee;
    margin: 0 4px;
    padding: 4px 8px;
  }

  .projectName {
    font-size: 12px;
    margin-bottom: 4px;

    /* stylelint-disable-next-line no-descending-specificity */
    .anticon {
      font-size: 15px;
      margin-right: 3px;
    }
  }

  .documentFormName {
    font-size: 16px;
  }
`;
export default FillDocumentForm;
