import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { useLazyQuery, useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import type { UpdateAttributesMutation } from '@graphql/types/graphql';
import type { AttributeToUpdate } from '../constants';

type UpdateAttributesParams = {
  attributes: AttributeToUpdate[];
  onCompleted: (data: UpdateAttributesMutation) => void;
};

export const RECORD_ATTRIBUTES_QUERY = graphql(`
  query PrimaryRecordAttributes(
    $primaryRecordInput: PrimaryRecordInput!
    $input: RecordAttributesInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      attributes(input: $input) {
        nodes {
          ... on SystemAttribute {
            _id
            name
            value
            type
            selectedOptions {
              _id
              value
            }
            options {
              _id
              value
            }
          }
          ... on CustomAttribute {
            _id
            name
            value
            type
            selectedOptions {
              _id
              value
            }
            options {
              _id
              value
            }
          }
        }
        totalCount
      }
    }
  }
`);

const UPDATE_ATTRIBUTES_MUTATION = graphql(`
  mutation UpdateAttributes($data: UpdateRecordAttributesInput!) {
    updateRecordAttributes(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useRecordAttributes = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const [getAttributesQuery, { refetch }] = useLazyQuery(
    RECORD_ATTRIBUTES_QUERY,
  );
  const [updateAttributesMutation] = useMutation(UPDATE_ATTRIBUTES_MUTATION);

  const getAttributes: GetRowData = useCallback(async () => {
    const { data } = await getAttributesQuery({
      variables: {
        primaryRecordInput: { id: primaryRecordId },
      },
    });

    return {
      rowData: data?.primaryRecord?.attributes.nodes || [],
      //? Fix for preventing ag-grid from fetching more rows than available
      rowCount: Math.min(data?.primaryRecord?.attributes.totalCount || 0, 60),
    };
  }, [primaryRecordId, getAttributesQuery]);

  const updateAttributes = useCallback(
    ({ attributes, onCompleted }: UpdateAttributesParams) => {
      return updateAttributesMutation({
        variables: {
          data: {
            id: primaryRecordId,
            reference: 'primary',
            attributes,
          },
        },
        onCompleted,
      });
    },
    [primaryRecordId, updateAttributesMutation],
  );

  return {
    refetchAttributes: refetch,
    updateAttributes,
    getAttributes,
  };
};
