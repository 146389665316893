import { Input, Select } from 'antd';
import debounce from 'lodash/debounce';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { RECORD_TYPES, type RecordTypes } from '@trustlayer/common';

import useSetState from '@common/hooks/useSetState';

import { FullWidthSelect } from '@trustlayer/ui';
import { AttributeTypes, Operators } from './constants';

import InfiniteScrollSelect from '../InfiniteScrollSelect';
import Spinner from '../Spinner';
import { useAttributesQuery } from './hooks/useAttributesQuery';

import type { Attribute } from '@graphql/types/graphql';

export type AttributeFilter = {
  _id: string | undefined;
  operator: string | undefined;
  value: string | undefined;
  type: AttributeTypes;
};

type AttributesFilterProps = {
  filter?: AttributeFilter;
  recordType: RecordTypes;
  onChange: (selectedFilter: AttributeFilter | null) => void;
};

const initialState: AttributeFilter = {
  _id: undefined,
  operator: undefined,
  value: undefined,
  type: AttributeTypes.Text,
};

function getAttributeByUuid(attributes: Attribute[], attributeUuid?: string) {
  return attributes.find((attribute) => attribute._id === attributeUuid);
}

export const AttributeFilter = ({
  filter,
  recordType = RECORD_TYPES.Primary,
  onChange,
}: AttributesFilterProps) => {
  const [output, setOutput] = useSetState(filter || initialState);
  const [searchedAttribute, setSearchedAttribute] = useState('');
  const debouncedOnChange = useMemo(() => debounce(onChange, 500), [onChange]);

  const {
    attributes,
    loading: areAttributesLoading,
    attributesTotalCount,
    filterAttributesByName,
    fetchMoreAttributes,
  } = useAttributesQuery({
    recordType,
  });

  const selectedAttribute = getAttributeByUuid(attributes, output._id);

  useEffect(() => {
    const isValuedFilterApplied =
      output._id && output.operator === Operators.IS_EMPTY.key;
    const isEmptyFilterApplied = output._id && output.operator && output.value;

    if (isValuedFilterApplied || isEmptyFilterApplied) {
      debouncedOnChange(output);
    }
  }, [output, debouncedOnChange]);

  const onAttributeChanged = (attributeUuid: string) => {
    const type = getAttributeByUuid(attributes, attributeUuid)?.type;

    /**
     * @note initialState is used to reset previous selected values
     */
    setOutput({ ...initialState, type, _id: attributeUuid });
  };

  const onAttributeCleared = () => {
    onChange(null);
    setOutput(initialState);
  };

  const handleOperatorChanges = (operator: string) => {
    if (operator === Operators.IS_EMPTY.key) {
      setOutput({ operator, value: initialState.value });
    }

    setOutput({ operator });
  };

  const handleInputValueChanges = (
    evt: React.ChangeEvent<{ value: string }>,
  ) => {
    setOutput({ value: evt.currentTarget.value });
  };

  const handleSelectValueChanges = (value: string) => {
    setOutput({ value });
  };

  const InputValue =
    selectedAttribute?.type === AttributeTypes.Dropdown ? (
      <FullWidthSelect
        placeholder="Select Option..."
        onChange={handleSelectValueChanges}
      >
        {selectedAttribute.options?.map(
          (option: { _id: string; value: string }) => (
            <Select.Option key={option._id} value={option.value}>
              {option.value}
            </Select.Option>
          ),
        )}
      </FullWidthSelect>
    ) : (
      <StyledInput
        data-cy="attributesFilterValueInput"
        value={output.value}
        type={selectedAttribute?.type}
        placeholder="Enter value..."
        style={{ width: '100%' }}
        onChange={handleInputValueChanges}
      />
    );

  return (
    <StyledWrapper>
      <InfiniteScrollSelect
        showSearch
        allowClear
        value={output._id}
        loader={<Spinner />}
        isDataLoading={areAttributesLoading}
        searchValue={searchedAttribute}
        onSearch={(value) => {
          filterAttributesByName(value);
          setSearchedAttribute(value);
        }}
        hasMore={attributes.length < attributesTotalCount}
        onChange={onAttributeChanged}
        onClear={onAttributeCleared}
        loadMore={() => {
          fetchMoreAttributes();
        }}
        options={attributes?.map((option) => ({
          value: option._id,
          label: option.name,
        }))}
      />

      <StyledSelect
        data-cy="attributesFilterOperatorSelect"
        value={output.operator}
        placeholder="Select..."
        onChange={handleOperatorChanges}
      >
        {Object.values(Operators)
          .filter(({ types }) => {
            return selectedAttribute?.type
              ? types.includes(selectedAttribute.type)
              : false;
          })
          .map(({ key, label }) => (
            <Select.Option key={key} value={key}>
              {label}
            </Select.Option>
          ))}
      </StyledSelect>
      {output.operator !== Operators.IS_EMPTY.key && InputValue}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
`;

const StyledSelect = styled(Select)`
  flex-grow: 1;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;
