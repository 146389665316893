import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import { LinkCell, Table, type TableRef } from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';

import { ComplianceStats } from '@common/components/ComplianceStats';
import RefreshDataBanner from '@common/components/RefreshDataBanner';
import {
  BulkOperationStatus,
  type ContextRecord,
  type RequestRecord,
} from '@graphql/types/graphql';
import { useOperationsUpdates } from '@modules/organization/hooks/useOperationsUpdates';
import { REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES } from '@modules/request-records/constants';
import { CreateRequestsModal } from '@modules/request-records/containers/CreateRequestsModal';
import { DeleteRequestsModal } from '@modules/request-records/containers/DeleteRequestsModal/DeleteRequestsModal';
import { getDeleteFilters } from './RequestsTable.utils';
import { ContextRecordCell } from './components/ContextRecordCell';
import { COLUMN_FIELDS } from './constants';
import { useRequests } from './hooks';

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedRequestIds: string[];
  selectedRowsData: any[];
};

export const RequestsTable = ({ recordId }: { recordId: string }) => {
  const tableRef = useRef<TableRef>(null);

  const [inputSearchValue, setInputSearchValue] = useState('');
  const [isCreateRequestsModalVisible, setIsCreateRequestsModalVisible] =
    useState(false);
  const [isRequestDeleteModalVisible, setIsRequestDeleteModalVisible] =
    useState(false);

  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
  });

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedRequestIds: [],
    selectedRowsData: [],
  });

  const [shouldRefreshData, setShouldRefreshData] = useState(false);

  const { getRequestsList } = useRequests({
    primaryRecordId: recordId,
  });

  useOperationsUpdates({
    operationNames: Object.values(REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES),
    operationStatuses: [BulkOperationStatus.Completed],
    onUpdate: () => {
      setShouldRefreshData(true);
    },
  });

  const refreshData = () => {
    setShouldRefreshData(false);
    tableRef?.current?.refreshTable();
  };

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Request Name',
        field: COLUMN_FIELDS.name,
        filter: 'agTextColumnFilter',
        lockPosition: 'left' as const,
        pinned: 'left' as const,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        minWidth: 200,
        sortable: false,
        cellRenderer: ({ data }: { data: RequestRecord }) => (
          <LinkCell to={`${data._id}`}>{data.name}</LinkCell>
        ),
      },
      {
        headerName: 'Related',
        field: COLUMN_FIELDS.related,
        minWidth: 200,
        sortable: false,
        valueGetter: ({ data }: { data: RequestRecord }) => data?.contextRecord,
        cellRenderer: ({ value }: { value: ContextRecord }) => (
          <ContextRecordCell name={value?.name} />
        ),
      },
      {
        headerName: 'Compliance',
        field: COLUMN_FIELDS.compliance,
        minWidth: 200,
        sortable: false,
        valueGetter: ({ data }: { data: any }) => data?.complianceStats,
        cellRenderer: ({ value }: any) => (
          <ComplianceStats
            compliantCount={value.compliantSubjects}
            totalCount={value.totalSubjects}
          />
        ),
      },
    ];
  }, []);

  const handleSelectionChange = (params: any) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedRequestIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleSelectVisibleItems = () => {
    tableRef?.current?.selectVisibleRows();
  };

  const resetItemsTableSelection = () => {
    tableRef?.current?.resetSelection();
    setCounters({ selectedRows: 0 });
    setSelection({
      isSelectAllChecked: false,
      selectedRequestIds: [],
    });
  };

  const handleSelectAllItems = () => {
    tableRef?.current?.selectAllRows();
  };

  const handleDelete = () => {
    setIsRequestDeleteModalVisible(true);
  };

  return (
    <>
      <DeleteRequestsModal
        count={counters.selectedRows}
        open={isRequestDeleteModalVisible}
        filters={getDeleteFilters({
          primaryRecordId: recordId,
          ids: selection.selectedRequestIds,
          textValue: inputSearchValue,
        })}
        onCompleted={() => {
          setIsRequestDeleteModalVisible(false);
          tableRef?.current?.refreshTable();
          resetItemsTableSelection();
        }}
        onScheduled={() => {
          setIsRequestDeleteModalVisible(false);
          resetItemsTableSelection();
        }}
        onCancel={() => {
          setIsRequestDeleteModalVisible(false);
        }}
      />
      <CreateRequestsModal
        open={isCreateRequestsModalVisible}
        recordId={recordId}
        onCancel={() => {
          setIsCreateRequestsModalVisible(false);
        }}
        onCompleted={() => {
          setIsCreateRequestsModalVisible(false);
          tableRef?.current?.refreshTable();
        }}
        onScheduled={() => {
          setIsCreateRequestsModalVisible(false);
        }}
      />
      <StyledWrapper>
        <TableHeader>
          <PageTitle title="Requests" />
          <div className="top-right-actions">
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCreateRequestsModalVisible(true);
              }}
            >
              New request
            </Button>
          </div>

          <div className="bottom-left-actions">
            {areBulkActionsButtonsVisible ? (
              <Space>
                <SelectionDropdown
                  areAllSelected={selection.isSelectAllChecked}
                  selectedEntitiesCount={counters.selectedRows}
                  onSelectAll={handleSelectAllItems}
                  onSelectAllVisible={handleSelectVisibleItems}
                  onSelectNone={resetItemsTableSelection}
                  totalCount={counters.totalRows}
                  totalVisible={counters.visibleRows}
                />

                <Button icon={<DeleteOutlined />} onClick={handleDelete}>
                  Delete
                </Button>
              </Space>
            ) : (
              <StyledSearchInputWrapper>
                <SearchInput
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  defaultValue={inputSearchValue}
                  onSearch={(value) => {
                    tableRef.current?.setFilters({
                      [COLUMN_FIELDS.name]: {
                        filterType: 'text',
                        filter: value,
                        type: 'contains',
                      },
                    });
                  }}
                />
              </StyledSearchInputWrapper>
            )}
          </div>
        </TableHeader>
        <StyledTable>
          {shouldRefreshData && (
            <RefreshDataBanner onRefreshClick={refreshData} />
          )}
          <Table
            isSideBarDisbaled
            ref={tableRef}
            columnDefs={columns}
            getRowData={getRequestsList}
            onSelectionChanged={handleSelectionChange}
          />
        </StyledTable>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSearchInputWrapper = styled.div`
  width: 280px;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;
