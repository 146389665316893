import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Alert, Button, Tooltip } from 'antd';
import styled from 'styled-components';

import FullWidthSpace from '@common/components/FullWidthSpace';

import { dispatchDeleteNoteModalEvent } from '../hooks';

export const PublicNote = ({
  requirementId,
  publicNotes,
  selectedProject,
  deletable = false,
  isVisible,
}: {
  requirementId: string;
  publicNotes: string;
  selectedProject: string;
  deletable?: boolean;
  isVisible?: boolean;
}) => {
  const VisibilityIcon =
    typeof isVisible === 'boolean'
      ? isVisible
        ? EyeOutlined
        : EyeInvisibleOutlined
      : null;

  return (
    <AlertSmallerText
      message={
        <FullWidthSpace
          style={{
            justifyContent: 'space-between',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: publicNotes,
            }}
          />
          <div>
            {VisibilityIcon && (
              <Tooltip
                placement="left"
                title={
                  isVisible
                    ? 'Note will be hidden on document request page when compliant'
                    : 'Note hidden on document request page'
                }
              >
                <VisibilityIcon />
              </Tooltip>
            )}
            {deletable && (
              <SmallButton
                size="small"
                type="ghost"
                icon={<DeleteOutlined />}
                onClick={() => {
                  dispatchDeleteNoteModalEvent({
                    _id: requirementId,
                    selectedProject,
                  });
                }}
              />
            )}
          </div>
        </FullWidthSpace>
      }
      type="warning"
    />
  );
};

const SmallButton = styled(Button)`
  font-size: 12px !important;
  padding: 0 !important;
  height: auto !important;
  color: rgba(0, 0, 0, 0.88);
  border: 0 !important;
  box-shadow: none !important;

  &:hover {
    opacity: 0.5;
  }
`;

const AlertSmallerText = styled(Alert)`
  width: 100%;
  margin: 5px 0;

  p {
    font-size: 12px !important;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ul {
    margin-left: 12px;
  }

  ol {
    margin-left: 14px;
  }
`;
