import type {
  DateFilter,
  SetFilter,
  TableRef,
  TextFilter,
} from '@common/components/Table';
import { FILTER_TYPES } from '@common/constants/filters';

export const FILTERABLE_COLUMN_FIELDS = {
  name: 'name',
  status: 'status',
  complianceStatus: 'complianceStatus',
  startDate: 'startDate',
  endDate: 'endDate',
  attributes: 'attributes',
} as const;

export const COLUMN_FIELDS = {
  ...FILTERABLE_COLUMN_FIELDS,
  actions: 'actions',
};

export const SERVER_FILTERS_CONFIG = {
  [COLUMN_FIELDS.name]: {
    name: 'name',
    filterType: FILTER_TYPES.string,
  },
  [COLUMN_FIELDS.status]: {
    name: 'status',
    filterType: FILTER_TYPES.options,
  },
};

export type TableFiltersModel = {
  [FILTERABLE_COLUMN_FIELDS.name]?: TextFilter;
  [FILTERABLE_COLUMN_FIELDS.status]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.complianceStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.startDate]?: DateFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.endDate]?: DateFilter<number>;
};

export type ContextRecordsTableRef = TableRef;
