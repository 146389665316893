import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { Typography, message } from 'antd';
import { useArchiveContextRecordMutation } from '../../hooks/useArchiveContextRecordMutation';
import { useContextRecordQuery } from './hooks/useContextRecordQuery';

type ArchiveContextRecordModalProps = {
  open: boolean;
  contextRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const ArchiveContextRecordModal = ({
  open,
  contextRecordId,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: ArchiveContextRecordModalProps) => {
  const { contextRecord, loading } = useContextRecordQuery({
    id: contextRecordId,
    skip: !open || !contextRecordId,
  });

  const { archiveContextRecord, isArchivingContextRecord } =
    useArchiveContextRecordMutation();

  const handleArchive = async () => {
    // Safety check to avoid archiving without an ID
    if (!contextRecordId) {
      throw new Error('Context record ID is required');
    }

    const res = await archiveContextRecord(contextRecordId);

    const status = res.data?.archiveContextRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(`${contextRecord?.name} archived successfully.`);
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${contextRecord?.name} has been scheduled for archiving.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The archiving of ${contextRecord?.name} has failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  return (
    <ModalConfirm
      loading={loading}
      data-cy="archive-context-record-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isArchivingContextRecord,
      }}
      okText="Archive"
      title={`Are you sure you want to archive ${contextRecord?.name}?`}
      onCancel={onCancel}
      onOk={handleArchive}
    >
      <Typography.Paragraph>
        Please confirm that you want to archive {contextRecord?.name}
      </Typography.Paragraph>
      <ul>
        <li>It will not show up in your reports and dashboard</li>
      </ul>
    </ModalConfirm>
  );
};
