import {
  AttributeFilter,
  type AttributeFilter as AttributeFilterType,
} from '@common/components/AttributesFilter';
import type { RecordTypes } from '@trustlayer/common';
import type { CustomFilterProps } from '../types';
import { CustomFilterWrapper } from './CustomFilterWrapper';

import { useCallback } from 'react';

type AttributeCustomFilterProps = CustomFilterProps & {
  recordType: RecordTypes;
};

export const AttributeCustomFilter = ({
  model,
  onModelChange,
  recordType,
}: AttributeCustomFilterProps) => {
  const handleOnChange = useCallback(
    (selectedFilter: AttributeFilterType | null) => {
      onModelChange(selectedFilter);
    },
    [onModelChange],
  );

  return (
    <CustomFilterWrapper>
      <AttributeFilter recordType={recordType} onChange={handleOnChange} />
    </CustomFilterWrapper>
  );
};
