import {
  BarChartOutlined,
  BellOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FolderOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Badge, Menu } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import useEntityQueryString from '@common/hooks/useEntityQueryString';
import { useQuery } from '@graphql/hooks';
import {
  useIsBaseAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import {
  useMetabaseReports,
  useTrustLayerV2FeatureFlag,
} from '@modules/feature-flags/hooks';
import { openNotificationDrawer } from '@modules/notification/actions';
import { NOTIFICATION_BELL_TOTAL_COUNT_QUERY } from '@modules/notification/containers/NotificationsBell/queries';
import { NOTIFICATION_UPDATES_SUBSCRIPTION } from '@modules/notification/subscriptions';
import { getOrganizationMemberByUserId } from '@modules/organization-member/selectors';
import { getMyId } from '@modules/user/selectors';
import { useAppSelector } from '@store/hooks';

type NavigationMenuProps = {
  organizationCode: string;
};

const getSelectedKeys = (location: any) => {
  const selectedKeys = location.pathname.split('/').filter(Boolean);
  return selectedKeys.length > 1 ? [selectedKeys[1]] : [];
};

export const NavigationMenu = ({ organizationCode }: NavigationMenuProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  const selectedKeys = getSelectedKeys(location);
  const { getQsForEntityList } = useEntityQueryString();
  const { isMetabaseReportsFeatureFlagEnabled } = useMetabaseReports();
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();
  const handleOpenNotificationDrawer = () => dispatch(openNotificationDrawer());
  const userId = useAppSelector(getMyId);
  const recipient = useAppSelector((state) =>
    getOrganizationMemberByUserId(state, userId),
  );

  const { data, subscribeToMore } = useQuery(
    NOTIFICATION_BELL_TOTAL_COUNT_QUERY,
    {
      variables: {
        filter: {
          isRead: false,
        },
      },
      //! prevent query from running if organization is not in the redux store
      //! in the v2 of the app, this should not be necessary, because we need have a loader until organization is loaded
      skip: !recipient?._id,
    },
  );

  useEffect(() => {
    if (!recipient?._id) {
      return;
    }

    subscribeToMore({
      document: NOTIFICATION_UPDATES_SUBSCRIPTION,
      variables: { memberId: recipient?._id },
      updateQuery: (prevData) => {
        const { totalCount } = prevData.notifications || {};
        const newTotalCount = totalCount ?? 0 + 1;

        return {
          notifications: { totalCount: newTotalCount },
        };
      },
    });
  }, [subscribeToMore, recipient?._id]);

  const unreadTotalCount = data?.notifications?.totalCount || 0;

  const items = [
    {
      key: 'notifications',
      icon: (
        <Badge dot={unreadTotalCount > 0}>
          <BellOutlined />
        </Badge>
      ),
      label: 'Notifications',
      onClick: handleOpenNotificationDrawer,
    },
    ...(!isBaseAddOnEnabled
      ? [
          {
            key: 'dashboard',
            icon: <DashboardOutlined />,
            label: (
              <NavLink to={`/${organizationCode}/dashboard`}>Dashboard</NavLink>
            ),
          },
        ]
      : []),
    ...(isMetabaseReportsFeatureFlagEnabled
      ? [
          {
            key: 'reports',
            icon: <BarChartOutlined />,
            label: (
              <NavLink to={`/${organizationCode}/reports`}>Insights</NavLink>
            ),
          },
        ]
      : []),
    {
      key: 'parties',
      icon: <ShopOutlined />,
      label: (
        <NavLink
          to={`/${organizationCode}/parties?${getQsForEntityList('parties')}`}
        >
          Parties
        </NavLink>
      ),
    },
    ...(isContextsAddOnEnabled
      ? [
          {
            key: 'projects',
            icon: <FolderOutlined />,
            label: (
              <NavLink
                to={`/${organizationCode}/projects?${getQsForEntityList('projects')}`}
              >
                Projects
              </NavLink>
            ),
          },
        ]
      : []),
    {
      key: 'documents',
      icon: <FileTextOutlined />,
      label: (
        <NavLink
          to={`/${organizationCode}/documents?${
            isTlV2FeatureFlagEnabled ? '' : getQsForEntityList('documents')
          }`}
        >
          Documents
        </NavLink>
      ),
    },
  ];

  return (
    <StyledMenu
      selectedKeys={selectedKeys}
      mode="vertical"
      style={{ border: 'none' }}
      items={items}
    />
  );
};

const StyledMenu = styled(Menu)`
  background-color: transparent;

  .ant-menu-item-selected {
    background-color: #e6f4ff !important;
  }

  &.ant-menu-inline-collapsed .ant-badge .ant-badge-dot {
    top: 1em;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
