import { PaperClipOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ArchivedBanner } from '@common/components/ArchivedBanner';
import { CollapseHeader } from '@common/components/CollapseHeader';
import { ComplianceStats } from '@common/components/ComplianceStats';
import { PageTitle } from '@common/components/PageTitleWrapper';
import { TabButtons } from '@common/components/TabButtons';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import { CONTEXT_RECORDS_TABS } from '@modules/context-records';
import type { ContextRecordRoutesParams } from '@modules/router/types';
import { ContextRecordStatus } from '@trustlayer/common';
import { Breadcrumb, Collapse, Divider } from 'antd';
import { DetailsPageLayout } from 'layouts';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  useContextObjectGenerality,
  useContextRecordGenerality,
} from './hooks';

export const ContextRecordDetail = () => {
  const {
    organizationSlugifyName,
    objectSlug,
    recordId = '',
  } = useParams<ContextRecordRoutesParams>();
  const { pathname } = useLocation();
  const tab = pathname.split('/')[5];

  const navigate = useNavigate();

  const { contextRecord } = useContextRecordGenerality({
    contextRecordId: recordId,
  });
  const { contextObject } = useContextObjectGenerality({
    objectSlug,
  });

  const activeTab =
    Object.values(CONTEXT_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [activeSidebarPanelKeys, setActiveSidebarPanelKeys] = useState<
    string | string[]
  >([]);

  const handleClose = () => {
    navigate(`/${organizationSlugifyName}/c/${objectSlug}`);
  };

  const handleSidebarCollapse = (collapsed: boolean) => {
    if (collapsed) setActiveSidebarPanelKeys([]);
    setIsSidebarCollapsed(collapsed);
  };

  return (
    <DetailsPageLayout>
      {/* TOP CONTENT */}
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions onClose={handleClose} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <StyledObjectName>{objectSlug}</StyledObjectName>
          </Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      {/* MAIN CONTENT */}
      <DetailsPageLayout.MainContent>
        {contextRecord?.status === ContextRecordStatus.Archived && (
          <ArchivedBanner>
            {`This ${contextObject?.name} is archived`}
          </ArchivedBanner>
        )}

        <DetailsPageLayout.Header>
          <StyledTopLeftActions>
            <PageTitle
              title={contextRecord?.name}
              icon={getAntdIconOutlinedByName(contextObject?.icon)}
            />
            <Divider type="vertical" />
            <ComplianceStats
              compliantCount={
                contextRecord?.stats.requestRecords.compliantCount || 0
              }
              totalCount={contextRecord?.stats.requestRecords.totalCount || 0}
            />
          </StyledTopLeftActions>

          <StyledBottomLeftActions>
            <TabButtons
              tabs={CONTEXT_RECORDS_TABS}
              activeTab={activeTab}
              onClick={(tabKey) => {
                navigate(`./${tabKey}`, {
                  relative: 'path',
                });
              }}
            />
          </StyledBottomLeftActions>
        </DetailsPageLayout.Header>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet />
          </DetailsPageLayout.Body>
          <DetailsPageLayout.Sidebar
            collapsed={isSidebarCollapsed}
            onCollapse={handleSidebarCollapse}
            resizeStorageKey="contextRecordSidebarWidth"
          >
            <>
              <Collapse
                bordered={false}
                expandIconPosition="end"
                activeKey={activeSidebarPanelKeys}
                expandIcon={isSidebarCollapsed ? () => null : undefined}
                onChange={(data) => {
                  setIsSidebarCollapsed(false);
                  setActiveSidebarPanelKeys(data);
                }}
              >
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<UnorderedListOutlined />}
                      label={isSidebarCollapsed ? '' : 'Attributes'}
                    />
                  }
                  key="attributes"
                >
                  <span>Attributes panel</span>
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<PaperClipOutlined />}
                      label={isSidebarCollapsed ? '' : 'Attachments'}
                    />
                  }
                  key="attachments"
                >
                  <span>Attachments panel</span>
                </Collapse.Panel>
              </Collapse>
            </>
          </DetailsPageLayout.Sidebar>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledObjectName = styled.span`
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`;

const StyledTopLeftActions = styled.div.attrs({
  className: 'top-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomLeftActions = styled.div.attrs({
  className: 'bottom-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;
