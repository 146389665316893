import { CustomFieldType } from '@modules/custom-field/constants';

export const AttributeTypes = CustomFieldType;
export type AttributeTypes =
  (typeof CustomFieldType)[keyof typeof CustomFieldType];

export const OperatorsValues = {
  contains: 'contains',
  gt: 'is greater than',
  lt: 'is less than',
  eq: 'is equal to',
  empty: 'is empty',
};

type OperatorsType = Record<
  string,
  {
    key: string;
    label: string;
    types: Array<`${AttributeTypes}`>;
  }
>;

export const Operators: OperatorsType = {
  CONTAINS: {
    key: 'contains',
    label: 'contains',
    types: [AttributeTypes.Text],
  },
  IS_GREATER_THAN: {
    key: 'gt',
    label: 'is greater than',
    types: [AttributeTypes.Number],
  },
  IS_EQUAL_TO: {
    key: 'eq',
    label: 'is equal to',
    types: [
      AttributeTypes.Number,
      AttributeTypes.Text,
      AttributeTypes.Dropdown,
    ],
  },
  IS_LESS_THAN: {
    key: 'lt',
    label: 'is less than',
    types: [AttributeTypes.Number],
  },
  IS_EMPTY: {
    key: 'empty',
    label: 'is empty',
    types: [
      AttributeTypes.Number,
      AttributeTypes.Text,
      AttributeTypes.Dropdown,
    ],
  },
};
