import type { ThreeDotsMenuItem } from '@trustlayer/ui';
import { type ActionType, DOCUMENT_ACTION_TYPES } from '../../../../constants';

type GetDocumentListActionsParams = {
  handleOnClick: (actionType: ActionType) => void;
  excludedActions?: ActionType[];
  documentInfo: {
    isDetachPartyDisabled: boolean;
    isDetachProjectDisabled: boolean;
    isDocumentReviewed: boolean;
    isDocumentArchived: boolean;
  };
};

export const getActionMenuItems = ({
  handleOnClick,
  excludedActions = [],
  documentInfo: {
    isDetachPartyDisabled,
    isDetachProjectDisabled,
    isDocumentReviewed,
    isDocumentArchived,
  },
}: GetDocumentListActionsParams): ThreeDotsMenuItem<ActionType>[] => {
  const actions = [
    {
      disabled: false,
      label: 'Assign to party',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.assignToParty),
      code: DOCUMENT_ACTION_TYPES.assignToParty,
    },
    {
      disabled: isDetachPartyDisabled,
      label: 'Detach from current party',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.detachFromParty),
      code: DOCUMENT_ACTION_TYPES.detachFromParty,
    },
    {
      disabled: false,
      label: 'Associate to project',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.associateToProject),
      code: DOCUMENT_ACTION_TYPES.associateToProject,
    },
    {
      disabled: isDetachProjectDisabled,
      label: 'Detach from current project',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.detachFromProject),
      code: DOCUMENT_ACTION_TYPES.detachFromProject,
    },
    {
      disabled: false,
      label: isDocumentReviewed ? 'Mark as to review' : 'Mark as reviewed',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.updateReviewStatus),
      code: DOCUMENT_ACTION_TYPES.updateReviewStatus,
    },
    {
      disabled: false,
      label: isDocumentArchived ? 'Unarchive document' : 'Archive document',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.updateArchiveStatus),
      code: DOCUMENT_ACTION_TYPES.updateArchiveStatus,
    },
    {
      disabled: false,
      label: 'Delete document',
      onClick: () => handleOnClick(DOCUMENT_ACTION_TYPES.delete),
      code: DOCUMENT_ACTION_TYPES.delete,
    },
  ];

  return actions.filter(
    (action) => excludedActions.includes(action.code) === false,
  );
};
